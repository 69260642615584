<template>
  <div class="container">
    <nav-bar header-title="身份验证" :show-back="true" :header-back="appBack" />
    <div class="">
      <p class="p1 f24">为确保是您本人操作，请完成以下验证</p>

      <p class="p2 f32">我们已将验证码发送至您的手机</p>
      <p class="p3 f32">{{ mobilePhone }}</p>

      <div class="time-box">
        <van-field
          class="f32 fw6"
          v-model="captcha"
          @input="inputChange"
          autofocus
          center
          placeholder="请输入验证码"
        >
          <template #button>
            <div v-show="!isGetting" class="f28 fw6" @click="getSms">获取验证码</div>
            <van-count-down
              v-show="isGetting"
              @finish="timeFinish"
              :auto-start="false"
              :time="time"
              ref="countDown"
            >
              <template #default="timeData">
                <span class="block f28">重新发送{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </template>
        </van-field>
      </div>
      <div
        @click="confirm"
        :class="['button', 'row-center-center', disabled ? 'disabled' : '']"
      >
        确认注销
      </div>
    </div>

    <confirm
      type="2"
      :showConfirm="showConfirm"
      title="确认注销账号？"
      text="注销后，账号内信息和权益将被清除，且无法再恢复"
      :btnTextArr="['我再想想', '确认注销']"
      @firstBtn="cancel"
      @secondBtn="confirmSure"
    />
  </div>
</template>

<script>
import userMixin from "@/mixin/userMixin";

import { newAppBack, initBack, loginOut, getMobile } from "@/lib/appMethod";
import navBar from "@/components/nav-bar/nav-bar";
import confirm from "@/components/confirm/confirm";

export default {
  mixins: [userMixin],
  components: {
    navBar,
    confirm,
  },
  data() {
    return {
      disabled: true,

      time: 60 * 1000,
      mobilePhone: "",
      captcha: "",
      isGetting: false,
      showConfirm: false,
    };
  },
  computed: {},
  async created() {
    await this.$getAllInfo();
    initBack();

    this.mobilePhone = await getMobile();
    console.log("phone:", this.mobilePhone);
  },
  methods: {
    appBack: newAppBack,
    inputChange(v) {
      this.disabled = !v;
    },
    getSms() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      this.$axios
        .post(this.baseURLApp + "/user/account/cancellation/sendSms", {
          mobilePhone: this.mobilePhone,
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          this.$toast("验证码已发送");
          this.$refs.countDown.start();
          this.isGetting = true;
        });
    },
    timeFinish() {
      this.$refs.countDown.reset();
      this.isGetting = false;
    },
    confirm() {
      if (this.disabled) return;
      this.showConfirm = true;
    },
    confirmSure() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      this.$axios
        .post(this.baseURLApp + "/user/account/cancellation", {
          captcha: this.captcha,
          mobilePhone: this.mobilePhone,
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          this.$toast.clear();
          this.showConfirm = false;
          loginOut();
        })
        .catch(() => {
          this.showConfirm = false;
        });
    },
    cancel() {
      this.showConfirm = false;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  padding: calc(130px + env(safe-area-inset-top)) 32px 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 32px 32px;

  * {
    box-sizing: border-box;
  }

  .p1 {
    color: #6c727a;
    margin: 54px 0 58px;
  }

  .p2 {
    margin: 0 0 24px;
  }

  .p3 {
    margin: 0 0 72px;
  }
  .time-box {
    .van-cell {
      height: 100px;
      background-color: #f5f5f5;
      border-radius: 8px;
    }
    .van-count-down {
      min-width: 150px;
    }
    .block {
      color: #babbbb;
    }
  }
  .button {
    width: 686px;
    height: 96px;
    background: #242831;
    color: #fff;
    border-radius: 8px;
    margin: 176px 0 0;

    &.disabled {
      color: #9aa1a9;
      background: #dddddd;
    }
  }
}
</style>
