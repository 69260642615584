<template>
  <div class="container">
    <nav-bar header-title="账号注销协议" :show-back="true" :header-back="appBack" />
    <div class="f24">
      在您确认注销您的超鹿账号之前，请您充分阅读、理解并同意以下事项，如您不
      同意下列任一内容，或无法准确理解任何条款的含义，请不要进行账号注销操作，
      您应确保您有权决定本账号的注销事宜，不侵犯任何第三方的合法权益，否则如
      因此引发任何争议，将由您自行承担<br /><br />
      一、账号注销前，需满足以下条件<br />
      1.账号是您本人通过超鹿运动 app 或微信小程序注册并由您本人使用的账户<br />
      2.无未完成的订单，无已提供服务但未支付的订单/服务<br />
      3.无任何拖欠款项，相关财产性权益已经结清<br />
      4.无进行中的纠纷投诉或者处罚，包括但不限于被冻结后未解冻或恢、复投
      诉举报和被投诉举报<br /><br />
      二、在账号注销前，为识别您的权利人身份，保障账号和账号内财产权益的<br />
      安全，我们需要进行注销申请的身份验证(如注册手机验证方式等)。如果您拒绝
      提供验证，或者“身份验证”失败，将无法继续完成注销服务。<br /><br />
      三、账号注销后，您将无法再使用该账号。您确认注销的行为即代表您接受
      所有注销后果，包括但不限于：<br />
      无法登录、使用超鹿账号，并移出该账号下的所有登录方式<br />
      该账号中的个人资料和历史数据（如运动数据、身体数据等）都将无法找回<br />
      账号中所有的资产及权益（如鹿分、优惠券、续转会员权益等）被清除<br />
      账号中的订单和交易记录都将无法找回<br /><br />
      四、注销账号重新注册，不可以领取新人权益。新人权益需要满足超鹿 id、
      手机号、设备号、支付 id、绑定微信均为新用户<br /><br />
      五、超鹿运动账号注销期间，如果您的超鹿运动账号涉及争议纠纷，包括但
      不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，我们有权自行中止您的
      注销行为，待上述事件结束后再进行注销，同时我们有权限制相同信息在注销后
      再次注册。<br /><br />
      六、注销本超鹿运动账号并不代表本超鹿运动账号注销前的账号行为和相关
      责任得到豁免或减轻。<br /><br />
      七、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法<br />
      律。因本协议产生之争议，双方尽最大诚意进行友好协商，如果双方不能协商一
      致，均应依照中华人民共和国法律予以处理，并由福建省福州市鼓楼区法院管辖。
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {

    }
  },
  computed: {},
  async created() {
    await this.getAllInfo()
    initBack()
  },
  methods: {
    appBack: newAppBack,

  },
}
</script>
<style lang="less" scoped>
.container {
  padding: calc(130px + env(safe-area-inset-top)) 32px 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 32px 32px;
}
</style>