<template>
  <div class="container">
    <nav-bar header-title="注销账号" :show-back="true" :header-back="appBack" />
    <div class="">
      <div class="part-1 col-center-center">
        <img
          src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/account-cancellation/attention-icon.png"
          alt="" class="icon" />
        <p class="f28 fw6">
          注销后，您将无法使用当前账号，<br />账户信息将被删除无法找回，包括但不限于：
        </p>
      </div>

      <div class="part-2">
        <div class="warn-item flex">
          <span class="p1 f20 row-center-center flex-none">1</span>
          <p class="p2 f24">无法登录、使用超鹿账号，并移出该账号下的所有登录方式</p>
        </div>
        <div class="warn-item flex">
          <span class="p1 f20 row-center-center flex-none">2</span>
          <p class="p2 f24">账号中的个人资料和历史数据（如运动数据、身体数据等）</p>
        </div>
        <div class="warn-item flex">
          <span class="p1 f20 row-center-center flex-none">3</span>
          <p class="p2 f24">账号中所有的资产及权益（如鹿分、优惠券、续转会员权益等）</p>
        </div>
        <div class="warn-item flex">
          <span class="p1 f20 row-center-center flex-none">4</span>
          <p class="p2 f24">账户中的订单和交易记录</p>
        </div>
      </div>

      <div class="part-3 col-center-center">
        <van-checkbox v-model="agree">
          <p class="f22">
            我已阅读并同意<span style="color: #267dff" @click="$router.push('/account-cancellation/agreement')">《账户注销协议》</span>
          </p>
        </van-checkbox>
        <div @click="next" :class="['button', 'f28', 'fw5', 'row-center-center', agree ? '' : 'disabled']">
          下一步
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from "@/mixin/userMixin";
import { newAppBack, initBack } from "@/lib/appMethod";
import navBar from "@/components/nav-bar/nav-bar";
export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      agree: false,
    };
  },
  computed: {},
  async created() {
    await this.$getAllInfo();
    initBack();
  },
  methods: {
    appBack: newAppBack,
    async next() {
      if (!this.agree) {
        this.$toast("请先阅读并同意账户注销协议")
        return
      }
      await this.checkAccount()

      this.$router.push("/account-cancellation/fail")
    },
    checkAccount() {
      return new Promise((resolve) => {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          overlay: true,
          loadingType: "spinner",
        })
        this.$axios
          .post(this.baseURLApp + "/user/account/cancellation/check", {
            userId: this.userId,
            token: this.token,
          })
          .then(() => {
            this.$toast.clear();
            resolve()
          });
      })
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f9fafb;
  padding: 130px 0 32px;
  padding: 130px 0 32px;
  padding: calc(130px + env(safe-area-inset-top)) 0 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 0 32px;

  * {
    box-sizing: border-box;
  }

  .part-1 {
    text-align: center;
    padding: 56px 80px;

    .icon {
      width: 80px;
      margin: 0 0 44px;
    }
  }

  .part-2 {
    background-color: #fff;
    padding: 1px 32px 56px;

    .warn-item {
      margin: 56px 0 0;

      .p1 {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #242831;
        color: #242831;
        margin: 0 24px 0 0;
      }

      .p2 {
        color: #3c454e;
      }
    }
  }

  .part-3 {
    width: 100%;
    padding: 24px 0 24px;
    padding: 24px 0 24px;
    padding: 24px 0 calc(24px + env(safe-area-inset-bottom));
    padding: 24px 0 calc(24px + constant(safe-area-inset-bottom));
    position: fixed;
    bottom: 0;
    left: 0;

    /deep/.van-checkbox__icon {
      height: 28px;
    }

    /deep/.van-checkbox__icon .van-icon {
      width: 30px;
      height: 30px;
      line-height: 28px;
    }

    .button {
      width: 686px;
      height: 88px;
      background: #242831;
      color: #fff;
      border-radius: 8px;
      margin: 22px 0 0;

      &.disabled {
        color: #9aa1a9;
        background: #dddddd;
      }
    }
  }
}
</style>
