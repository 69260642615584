<template>
  <div :class="['container', appTypeStr === 'mini' ? '' : 'pd']">
    <nav-bar header-title="注销账号" :show-back="true" :header-back="appBack" />
    <div v-if="hasFailedReason">
      <div class="part-1 col-center-center">
        <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/account-cancellation/fail-icon.png" alt="" class="icon">
        <p class="f28 fw5">抱歉，由于以下原因，账号无法注销</p>
      </div>

      <div class="part-2 col-start-center">
        <div class="warn-item row-between-center" v-for="item in failedReason" :key="item.title" @click="goReason(enums[item.userAccountCancellationCheckEnum].go)">
          <div class="ellipsis">
            <p class="p1 f28 ellipsis">{{ enums[item.userAccountCancellationCheckEnum].title  }}</p>
            <p class="p2 f24 ellipsis">{{ enums[item.userAccountCancellationCheckEnum].content }}</p>
          </div>
          <van-icon class="flex-none" name="arrow" />
        </div>

      </div>

      <div class="part-3 col-center-center">
        <p class="f22">如对上述内容有疑问，请<span @click="gotoCustomerService('账号注销')" style="color: #267DFF;">联系客服</span></p>
      </div>

    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import {
  newAppBack,
  initBack,
  goMyClassBag,
  gotoCustomerService,
  gotoOrderList,
  goMyMoney,
  goVipCenter,
  goStayCourse
} from '@/lib/appMethod'

import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      hasFailedReason: false,
      failedReason: [],

      enums: {
        'CHECK_UNUSED_BALANCE': {
          title: '您账户中有待使用余额',
          content: '请先清空账户余额',
          go: 'goMyMoney'
        },
        'CHECK_UNUSED_COURSE_PACKAGE': {
          title: '您账户中有待使用课包',
          content: '请先清空账户课包',
          go: 'goMyClassBag'
        },
        'CHECK_UNUSED_GIFT': {
          title: '您账户中有待使用礼品卡',
          content: '请先清空账户礼品卡',
          go: 'goMyGift'
        },
        'CHECK_NO_EXPIRED_MEMBERSHIP_CARD': {
          title: '您的月卡还在有效期内',
          content: '请等待月卡权益过期',
          go: 'goMyCard'
        },
        'CHECK_NO_RETURN_DEPOSIT': {
          title: '您还未退回会员押金',
          content: '请先申请退回会员押金',
          go: 'goVipCenter'
        },
        'CHECK_NO_FINISHED_COURSE': {
          title: '您账户内还有未完成的课程',
          content: '请等待课程结束或取消课程',
          go: 'goStayCourse'
        },
        'CHECK_NO_PAY_SELFHELP_ORDER': {
          title: '您账户内还有待支付的订单',
          content: '请先支付订单',
          go: 'gotoOrderList'
        },
        'EQUITY_CARD_WAIT_EXPIRED': {
          title: '您账户中有待使用的权益卡',
          content: '请先用完账户内的权益卡',
          go: 'goMyCard'
        },
      },
      func: {
        'goMyGift': () => {
          this.$router.push('/myGift/index')
        },
        'goMyCard': () => {
          this.$router.push('/month-card/my-card')
        },
        'goMyMoney': goMyMoney,
        'goMyClassBag': goMyClassBag,
        'goVipCenter': goVipCenter,
        'goStayCourse': goStayCourse,
        'gotoOrderList': gotoOrderList
      }
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    this.checkAccount()
  },
  methods: {
    appBack: newAppBack,
    gotoCustomerService,
    checkAccount() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/user/account/cancellation/check', {userId: this.userId, token: this.token}).then((res) => {
        this.$toast.clear()
        const arr = Array.isArray(res.data) ? res.data : []
        // checkFlag false验证不通过   true验证通过不展示
        this.failedReason = arr.filter((item) => item.checkFlag === false)
        // 验证全部通过，跳转至注销确认页
        if (this.failedReason.length) {
          this.hasFailedReason = true
        } else {
          this.$router.replace('/account-cancellation/confirm')
        }

      })
    },
    goReason(str) {
      this.func[str]()
    }

  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #F9FAFB;
  padding: 0 0 32px;
  padding: 0 0 32px;
  &.pd {
    padding: calc(130px + env(safe-area-inset-top)) 0 calc(200px + env(safe-area-inset-bottom));
    padding: calc(130px + constant(safe-area-inset-top)) 0 calc(200px + constant(safe-area-inset-bottom));
  }
  * {
    box-sizing: border-box;
  }

  .part-1 {
    text-align: center;
    padding: 56px 80px;
    .icon {
      width: 80px;
      margin: 0 0 44px;
    }
  }

  .part-2 {

    .warn-item {
      padding: 0 24px 0 36px;
      width: 686px;
      height: 124px;
      background: #FFFFFF;
      border-radius: 8px;
      margin: 24px 0 0;

      .p2 {
        color: #9AA1A9;
        margin: 12px 0 0;
      }

      .van-icon {
        font-size: 32px;
        color: #9AA1A9;
      }
    }
  }

  .part-3 {
    width: 100%;
    background-color: #F9FAFB;
    padding: 24px 0 calc(100px + env(safe-area-inset-bottom));
    padding: 24px 0 calc(100px + constant(safe-area-inset-bottom));
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
